import * as React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SiteWrapper from '../components/siteWrapper';
import Page from '../components/page/pageNew';
import { FilePdf } from 'styled-icons/boxicons-solid/';

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Satzung = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.darkGray};
`;

const IndexPage = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;

    return (
        <SiteWrapper title="Impressum - SV Kappel">
            <Page headerImg={node.frontmatter.headerImg} html={node.html}>
                <Wrap>
                    <Satzung
                        href={`${node.frontmatter.satzung.publicURL}`}
                        target="_blank"
                    >
                        <FilePdf size={70} />
                        <span>Satzung</span>
                    </Satzung>
                </Wrap>
            </Page>
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query ImpressumQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "impressum" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                            satzung {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    }
`;
